@import "variables", "media-queries", "mixins", "typography";


.sizeSmall {
    display: grid;
    max-width: 580px;
    grid-template-columns: repeat(auto-fill, 50px);
    grid-gap: 15px;

    > li {
        height: 34px;

    }

    @include media-breakpoint(sm) {
        max-width: 320px;
    }

    @include media-breakpoint(370px) {
        max-width: 245px;
    }
}

.sizeBig {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;

    @include media-breakpoint(md) {
        justify-content: center;
    }

    @include media-breakpoint(sm) {
        justify-content: flex-start;
    }

    > li {
        height: 56px;
        margin-bottom: 20px;
        flex-shrink: 0;
        margin-left: 10px;
        margin-right: 10px;
    }


}
