@import "variables", "media-queries", "mixins", "typography";
.sc-section-promo {
    @include promo-header-indent;
    color: $color-text-primary;
    position: relative;
    overflow: hidden;

    &--wide {
        .sc-heading {
            max-width: 800px;
        }
    }

    &__bg {
        position: absolute;
        top: -160px;
        left: 50%;
        transform: translateX(-45%);
        width: 2800px;
        height: 1418px;
        filter: blur(10px);
    }

    &__container {
        position: relative;
        z-index: 1;
    }

    &--height-stretch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }

    &--height-fullscreen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    }

    &--with-reviews {
        @media (min-width: 993px) and (max-height: 800px) {
            padding-top: 75px;
        }
    }

    &--type-cols {
        padding-top: 80px;
        padding-bottom: 100px;

        @include media-breakpoint(md) {
            padding-top: 60px;
            padding-bottom: 80px;
        }

        @include media-breakpoint(sm) {
            padding-bottom: 60px;
        }

        &.sc-section-promo--with-breadcrumbs {
            padding-top: 10px;
        }

        .sc-section-promo__breadcrumbs {
            padding-bottom: 80px;

            @include media-breakpoint(md) {
                padding-bottom: 60px;
            }
        }

        .sc-section-promo__inner {
            display: grid;
            grid-template-columns: minmax(500px, 630px) minmax(350px, 470px);
            grid-gap: 70px;
            align-items: center;

            @include media-breakpoint(md) {
                display: block;
            }
        }

        &.sc-section-promo--wide {
            .sc-section-promo__inner {
                grid-template-columns: minmax(500px, 685px) minmax(350px, 415px);
            }
        }

        .sc-section-promo__left {
            @include media-breakpoint(md) {
                margin-bottom: 20px;
                text-align: center;
            }
        }

        .sc-section-promo__right {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: auto;
            }
        }

        .sc-section-promo__btn-wrap {
            margin-top: 30px;
        }
    }

    &--type-center {
        padding-top: 80px;
        padding-bottom: 100px;
        text-align: center;

        &.sc-section-promo--with-breadcrumbs {
            padding-top: 10px;
        }

        @include media-breakpoint(md) {
            padding-top: 60px;
            padding-bottom: 80px;
        }

        @include media-breakpoint(sm) {
            padding-bottom: 60px;
        }

        .sc-section-promo--with-breadcrumbs {
            padding-top: 10px;
        }

        .sc-section-promo__breadcrumbs {
            padding-bottom: 80px;

            @include media-breakpoint(md) {
                padding-bottom: 60px;
            }
        }

        .sc-section-promo__btn-wrap {
            margin-top: 30px;
        }

        .sc-heading {
            text-align: center;

            .sc-heading__title {
                text-align: center;
            }
        }
    }

    &--type-calculator {
        padding-top: 78px;
        padding-bottom: 120px;
        min-height: 730px;

        @include media-breakpoint(md) {
            padding-top: 65px;
        }

        @include media-breakpoint(sm) {
            padding-top: 50px;
            padding-bottom: 45px;
        }

        .sc-heading__title {
            margin-bottom: 15px;

            @include media-breakpoint(md) {
                margin-bottom: 10px;
            }
        }

        .sc-section-promo__inner {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include media-breakpoint(md) {
                display: block;
            }
        }

        .sc-section-promo__left {
            text-align: left;
            max-width: 680px;
            width: 100%;
            margin-right: 20px;

            @include media-breakpoint(md) {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
            }
        }

        .sc-section-promo__right {
            max-width: 420px;
            flex-shrink: 0;
            width: 100%;

            @include media-breakpoint(lg) {
                width: auto;
            }

            @include media-breakpoint(md) {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .sc-section-promo__btn-wrap--align-center {
            max-width: max-content;

            @include media-breakpoint(md) {
                max-width: 100%;
            }

            .sc-section-promo__after-button-text {
                text-align: center;
            }
        }
    }

    &__after-button-text {
        @include body-medium;
        margin-top: 15px;
    }
    &__reviews {
        margin-top: 60px;

        @include media-breakpoint(md) {
            margin-top: 50px;
        }

        @include media-breakpoint(sm) {
            margin-top: 30px;
        }
    }

    &__bullet {
        position: relative;
        top: 2px;
    }

    &__advantages {
        columns: 2;

        > li {
            display: flex;
            gap: 12px;
            align-items: center;
            font-size: 20px;
            line-height: 1.4;
            font-weight: 600;
            margin-bottom: 20px;
        }

        @include media-breakpoint(md) {
            text-align: left;
        }

        @include media-breakpoint(sm) {
            columns: 1;
            margin-bottom: 25px;
        }
    }

    &__badges-title {
        @include h4;
        text-align: center;
        margin-bottom: 25px;
    }
}
