$font-base: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-headings: $font-base;

$font-size: 16px;

$breakpoints: (
    "xxs": 399px,
    "xs": 576px,
    "sm": 767px,
    "md": 992px,
    "lg": 1200px,
    "xl": 1400px,
);

// ** Layout styles ** //
$container-width: 1170px;
$container-indent: 30px;
$container-indent-sm: 15px;
$container-full-width: $container-width + $container-indent * 2;

$section-vertical-indent: 100px;
$section-vertical-indent-md: 80px;
$section-vertical-indent-sm: 60px;

// ** UI styles ** //
$ui-element-min-height: 50px;
$textarea-min-height: 90px;
$field-max-height: 500px;
$field-padding-vertical: 16px;

// ** Color styles ** //
$color-basic-border: #dfe6f5;
$color-basic-site-bg: #f9fafd;
$color-basic-primary: #2d4eed;
$color-basic-primary-bg: #cdd6ff;
$color-basic-secondary: #585f74;
$color-basic-accent: #ffa800;

$color-basic-primary-hover: #1835c5;

$color-mark-bg: #dbe3fd;

$color-section-default-bg: #ffffff;
$color-section-inverted-bg: #f5f7fb;

$color-red: #f36a6a;
$color-green: #24b65f;
$color-green-bg: #eaf8ef;
$color-green-light: #a7eac1;
$color-orange: #ffa800;
$color-white: #ffffff;
$color-black: #001434;
$color-gray-light: #f9faff;
$color-grey-normal: #babdc8;
$color-gray-dark: #585f74;

$color-status-success: #43ca7c;
$color-status-info: #4c95f7;
$color-status-muted: #7688a7;
$color-status-warning: #ffc451;
$color-status-danger: #f36a6a;

$color-text-link: #0f75ff;
$color-text-primary: #001434;
$color-text-secondary: #2f466b;
$color-text-inverted: #ffffff;

$color-misc-hr: rgba(#2857a1, 0.06);
$color-misc-form-item-bg: #ffffff;
$color-misc-form-item-border: #d2d9f4;
$color-misc-card-default-border: #c7d1f6;
$color-misc-card-default-bg: #ffffff;
$color-misc-card-default-bg-alt: #f5f6f7;
$color-misc-card-glass-border: #fff;
$color-misc-card-glass-bg: rgba(#ffffff, 0.8);

$color-misc-card-glass-border-hover: #c7d1f6;

$color-misc-frame-border: #7b61ff;
$color-misc-frame-bg: #f5f6f7;
$color-misc-frame-title: #9fa8b0;

$color-highlight-card-bg: #eef2ff;

$color-skeleton-third: #f2f5ff;
$color-skeleton-first: #d2d9f4;
$color-skeleton-second: #e8edff;
$color-skeleton-primary: #788ffb;
$color-skeleton-green: #24b65f;

$color-breadcrumbs-inactive: rgba(#ecf3ff, 50%);
$color-breadcrumbs-active: #ecf3ff;
$color-breadcrumbs-inactive-black: rgba(#7688a7, 50%);
$color-breadcrumbs-active-black: #7688a7;

$color-gray-9: #262626;

$color-article-heading: #4c5580;

// ** Gradient styles ** //
$gradient-main: linear-gradient(314.65deg, #9665fd -0.61%, #65b4fd 100%);
$gradient-accent: linear-gradient(180deg, #ffd643 0%, #ffbc43 100%);
$gradient-accent-hover: linear-gradient(180deg, #ffc700 0%, #ffa400 100%);

$gradient-first: linear-gradient(103.43deg, #eefcff 9.21%, #eff7ff 49.74%, #f5f4ff 93.44%);
$gradient-second: linear-gradient(285.17deg, #eefcff 4.21%, #eff7ff 45.06%, #f5f4ff 89.11%);
$gradient-third: linear-gradient(100.2deg, #dcc8ec -3.25%, #67b2fd 46.26%, #8285fd 121.37%);
$gradient-fourth: linear-gradient(98.76deg, #7385e3 -3.16%, #5b8cd5 47.77%, #e7cfe1 104.08%);
$gradient-fifth: linear-gradient(274.42deg, #77b7f2 -0.96%, #8092ec 62.58%, #b894b4 115.58%);

$gradient-cta: linear-gradient(180deg, #f4eaff 0%, #e7f0ff 49.48%, #d8fcff 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

$gradient-cta-hover: linear-gradient(180deg, #f0e1ff 0%, #d5e6ff 49.48%, #c8fbff 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

$gradient-seo: linear-gradient(90deg, #f9fafd 52.52%, #eef3ff 100%);

// heading styles
$desktop-heading-h1-big: 62px;
$tablet-heading-h1-big: 52px;
$mobile-heading-h1-big: 36px;
$desktop-heading-h1-small: 52px;
$tablet-heading-h1-small: 40px;
$mobile-heading-h1-small: 32px;

$desktop-heading-h2: 46px;
$tablet-heading-h2: 34px;
$mobile-heading-h2: 26px;

$desktop-heading-h3-big: 38px;
$tablet-heading-h3-big: 34px;
$mobile-heading-h3-big: 26px;

$desktop-heading-h3-small: 32px;
$tablet-heading-h3-small: 26px;
$mobile-heading-h3-small: 24px;

$desktop-heading-h4: 24px;
$tablet-heading-h4: 22px;
$mobile-heading-h4: 20px;

$desktop-heading-h5: 20px;
$tablet-heading-h5: 18px;
$mobile-heading-h5: 18px;

$desktop-heading-h6: 18px;
$tablet-heading-h6: 18px;
$mobile-heading-h6: 16px;

// text styles
$desktop-body-big: 20px;
$desktop-body-default: 16px;
$desktop-body-medium: 14px;
$desktop-body-small: 12px;

$tablet-body-big: 18px;
$tablet-body-default: 16px;
$tablet-body-medium: 14px;
$tablet-body-small: 12px;

$mobile-body-big: 18px;
$mobile-body-default: 14px;
$mobile-body-medium: 12px;
$mobile-body-small: 10px;

// ** Effect styles ** //
$shadows-general-default: 0px 4px 10px rgba(23, 49, 143, 0.05), 0px 0px 1px rgba(23, 49, 143, 0.25);
$shadows-general-small: 0px 1px 2px rgba(23, 49, 143, 0.06), 0px 0px 1px rgba(23, 49, 143, 0.25);
$shadows-general-middle: 0px 3px 10px rgba(0, 60, 126, 0.1);
$shadows-general-large: 0px 4px 20px rgba(23, 49, 143, 0.1);
$shadows-general-hover: 0px 8px 10px 0px #{rgba(#17318f, 0.1)}, 0px 8px 10px 0px #{rgba(#17318f, 0.25)};

$shadows-button-glass: 0px 4px 5px rgba(0, 19, 86, 0.06), inset 0px 4px 15px rgba(255, 255, 255, 1);

$shadows-button-primary: 0px 4px 10px rgba(77, 105, 250, 0.5);
$shadows-button-primary-hover: 0px 6px 20px rgba(77, 105, 250, 0.2);
$shadows-button-accent: 0px 4px 10px rgba(255, 206, 83, 0.5);
$shadows-button-accent-hover: 4px 6px 14px 0px #{rgba(#ffbe1c, 0.35)},
    -4px -6px 14px 0px #{rgba(#ffbe1c, 0.35)};

$shadows-button-white: 0px 1px 2px rgba(23, 49, 143, 0.06), 0px 0px 1px rgba(23, 49, 143, 0.25);
$shadows-button-white-hover: 0px 7px 14px 0px #{rgba(#17318f, 0.1)}, 0px 0px 1px 0px #{rgba(#17318f, 0.25)};

$shadows-form-item: 0px 1px 1px rgba(23, 49, 143, 0.1), 0px 0px 1px rgba(23, 49, 143, 0.25);
$shadows-form-item-focus: 0px 1px 6px rgba(0, 48, 92, 0.1);
$shadows-card-default: 0px 4px 15px rgba(23, 49, 143, 0.06);
$shadows-card-glass: 0px 4px 15px rgba(23, 49, 143, 0.06), inset 0px 4px 10px rgba(255, 255, 255, 1),
    0px 0px 1px rgba(49, 89, 227, 0.25);

$shadows-card-default-hover: 4px 10px 16px 0px #{rgba(#17318f, 0.1)}, 0px 0px 1px 0px #{rgba(#3159e3, 0.25)},
    2px 6px 10px 0px #ffffff inset;

$shadows-card-glass-hover: 4px 10px 16px 0 #{rgba(#17318f, 0.1)}, 0 0 1px 0 #{rgba(#3159e3, 0.25)},
    2px 6px 10px 0 #ffffff inset;

$shadows-card-cta-default: -2px -4px 15px 0px #{rgba(#9c70df, 0.15)}, 2px 4px 15px 0px #{rgba(#8ec2c8, 0.2)};

$shadows-button-green: 0px 4px 10px rgba(36, 182, 95, 0.4);
$shadows-button-red: 0px 4px 10px rgba(#f36a6a, 0.4);

$shadows-select-hover: 0px 6px 12px 0px #{rgba(#17318f, 0.12)}, 0px 0px 1px 0px #{rgba(#17318f, 0.25)};

$shadows-card-cta-hover: -4px -8px 16px 0px #{rgba(#8349d9, 0.15)}, 4px 8px 16px 0px #{rgba(#73b6be, 0.2)};

$shadows-inner: 0px -8px 18px -5px #dee3ff inset;

$shadows-button-outline-default: 0px 4px 10px 0px #{rgba(#17318f, 0.05)},
    0px 0px 1px 0px #{rgba(#17318f, 0.25)};

// ** Radius styles ** //

$radius-big: 16px;
$radius-default: 12px;
$radius-medium: 8px;
$radius-small: 6px;
$radius-tiny: 4px;
