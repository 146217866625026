@import "variables", "media-queries", "mixins", "typography";
.popover {
    position: relative;
    width: fit-content;

    &:hover {
        .caption {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }
    &.burger {
        &:hover {
            .caption {
                opacity: 1;
                transform: translate(0, -50%);
            }
        }
    }
}

.caption {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translate(-50%, 10px);
    font-size: rem(14px);
    font-weight: 600;
    white-space: nowrap;
    background-color: $color-white;
    color: $color-text-primary;
    padding: 2px 4px;
    border-radius: 4px;
    opacity: 0;
    text-align: center;
    cursor: default;
    pointer-events: none;

    transition: opacity 0.25s, transform 0.25s;

    &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border: 4px solid transparent;
        border-top: 4px solid $color-white;
    }
}
.soon {
    padding: 10px 20px;
    bottom: unset;
    top: calc(100% - 4px);
    color: $color-basic-primary;
    border: 1px solid $color-basic-border;
    border-radius: 8px;

    &:after {
        display: none;
    }

    &:before {
        @include pseudo;
        width: 16px;
        height: 16px;
        background-color: $color-white;
        border: 1px solid $color-basic-border;
        border-bottom-color: transparent;
        border-right-color: transparent;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
    }
    &.burger {
        left: calc(100% + 20px);
        top: 50%;
        transform: translate(50%, -50%);
        &:before {
            width: 12px;
            height: 12px;
            left: 0;
            bottom: 50%;
            border-bottom-color: $color-basic-border;
            border-right-color: transparent;
            border-top-color: transparent;
            transform: translate(-50%, 50%) rotate(45deg);
        }
    }
    &.footer {
        top: unset;
        bottom: calc(100% + 6px);
        &:before {
            bottom: 0;
            top: 100%;
            transform: translate(-50%, -50%) rotate(45deg);
            border-bottom-color: $color-basic-border;
            border-right-color: $color-basic-border;
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }
}
