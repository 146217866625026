@import "variables", "media-queries", "mixins", "typography";
.footerMenu {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 360px;

    @include media-breakpoint(md) {
        margin-left: 0;
    }
}

.footerMenuItem {
    @include media-breakpoint(xs) {
        margin-bottom: 40px;
    }

    &:first-child {
        width: 170px;
    }

    &:last-child {
        width: 140px;
    }
}

.menuTitle {
    @include h4;
    color: inherit;
    margin-bottom: 30px;
    white-space: nowrap;

    @include media-breakpoint(md) {
        font-weight: 400;
        margin-bottom: 20px;
    }
}

.footerMenuList {
}

.item {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint(md) {
        padding-bottom: 8px;
        padding-right: 8px;
    }

    @include media-breakpoint(xs) {
        padding: 0;
    }
}

.link {
    transition: color 0.25s;

    &:hover {
        color: $color-basic-primary;
    }

    &Active {
        color: $color-basic-primary;
        font-weight: 700;
    }
}
.disabled {
    opacity: 0.6;
    pointer-events: none;
}
