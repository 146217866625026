@import "variables", "media-queries", "mixins", "typography";
.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 4px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    border-radius: $radius-default;
    padding: 13px 30px;
    min-width: fit-content;
    @include body-default-bold;
    transition: border 0.3s, color 0.3s, background-color 0.3s, background-image 0.3s, box-shadow 0.3s;
    user-select: none;

    &:not(.noHover) {
        cursor: pointer;
    }

    &.accent {
        background-image: $gradient-accent;
        box-shadow: $shadows-button-accent;
        color: $color-text-primary;

        &:hover:not(.noHover) {
            background-image: $gradient-accent-hover;
            box-shadow: $shadows-button-accent-hover;
        }

        &.outline {
            background: $color-white;
            border-color: $color-basic-accent;
        }
    }

    &.primary {
        background: $color-basic-primary;
        box-shadow: $shadows-button-primary;
        color: $color-text-inverted;

        &.disabled {
            background: $color-skeleton-primary;
        }

        &:hover:not(.noHover) {
            box-shadow: $shadows-button-primary-hover;
        }

        &.outline {
            background: $color-white;
            border-color: $color-basic-primary;
            color: $color-basic-primary;

            &.disabled {
                border-color: $color-skeleton-primary;
            }

            .spinner {
                div {
                    border-color: $color-basic-primary transparent transparent transparent;
                }
            }
        }
    }

    &.white {
        background: $color-white;
        box-shadow: $shadows-button-white;
        color: $color-text-primary;

        &:hover:not(.noHover) {
            box-shadow: $shadows-button-white-hover;
            color: $color-basic-primary-hover;
        }

        &.outline {
            border-color: $color-basic-border;
        }
    }

    &.link {
        background: transparent;
        color: $color-basic-primary;

        &.outline {
            box-shadow: none;
        }
    }

    &.outline {
        border: 2px solid transparent;
        box-shadow: $shadows-general-default;
    }

    &.withIcon {
        .icon,
        .inner {
            display: inline-block;
            vertical-align: middle;
        }

        &.outline {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &.white {
            i {
                color: $color-basic-primary;
            }
        }
    }

    &.disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    &.size-default {
        width: auto;
    }

    &.size-small {
        padding-left: 15px;
        padding-right: 15px;
    }

    &.size-tiny {
        font-size: 14px;
        padding: 10px;
    }

    &.size-xs {
        font-size: 12px;
        padding: 5px 8px;
        border-width: 1px;
        border-radius: 5px;
        font-weight: 600;
    }

    &.size-fullwidth {
        width: 100%;
    }

    &.iconOnly {
        padding: 18px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        flex: 0;
        padding: 0;

        .icon {
            margin-right: 0;
        }

        &.size-fullwidth {
            width: 100%;
            border-radius: $radius-default;
        }

        &.white {
            color: $color-status-muted;
        }
    }

    &.size-thin {
        padding: 5px 30px !important;
    }

    @include media-breakpoint(xs) {
        &.default,
        &.wide,
        &.small {
            width: 100%;
        }
    }
}

.spinner {
    vertical-align: middle;
    margin-right: 5px;

    + span {
        display: inline-block;
        vertical-align: middle;
    }
}
