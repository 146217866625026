@import "variables", "media-queries", "mixins", "typography";
.hamburger {
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    -webkit-tap-highlight-color: transparent;
    @include media-breakpoint(md) {
        display: flex;
    }

    &.colorWhite {
        .inner {
            &,
            &:before,
            &:after {
                background-color: $color-white;
            }
        }
    }

    &.colorBlack {
        .inner {
            &,
            &:before,
            &:after {
                background-color: $color-text-primary;
            }
        }

    }
}



.box {
    width: 42px;
    height: 42px;
    display: inline-block;
    position: relative;
}

.inner {
    top: calc(50% - 2px);

    transition-delay: none;

    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &:before {
        transition: top 0.075s 0.12s ease;
    }
    &:after {
        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &,
    &:before,
    &:after {
        position: absolute;

        width: 42px;
        height: 3px;

        background-color: $color-white;
        border-radius: 5px;

        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }
    &:before,
    &:after {
        content: "";
        display: block;
    }
    &:before {
        top: -10px;
    }
    &:after {
        bottom: -10px;
        width: 22px;
        left: auto;
        right: 0;
        transition: opacity 0.5s;
    }

    .active & {
        transform: rotate(-45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &:before {
            top: 0;
            transform: rotate(-90deg);
            transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &:after {
            bottom: 0;
            opacity: 0;
        }
    }
}
