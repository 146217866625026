@use "sass:math";
@use "variables" as var;
@use "media-queries" as media;

// Limit container width
@mixin limit-width($width: false) {
    @if $width {
        max-width: $width;
    }
    margin-left: auto;
    margin-right: auto;
}

// Customize scrollbars
@mixin customize-scrollbars(
    $size: 5px,
    $thumb: rgba(white, 0.1),
    $background: transparent,
    $visibility: hidden,
    $box-shadow: inset 1px 1px 1px rgba(black, 0.07)
) {
    /* Let's get this party started */
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #ffffff;
        box-shadow: $box-shadow;
        border-radius: 10px;
        background: $background;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-track {
        visibility: visible;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $thumb;
        visibility: $visibility;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    // &::-webkit-scrollbar-thumb:window-inactive {
    // }
}

@mixin line-clamp($count: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $count;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin fonts($size: false, $lh: false, $weight: false, $ls: false, $td: false, $tt: false) {
    @if $size {
        font-size: $size;
    }

    @if $lh {
        line-height: $lh;
    }

    @if $weight {
        font-weight: $weight;
    }
    @if $ls {
        letter-spacing: $ls;
    }
    @if $td {
        text-decoration: $td;
    }
    @if $tt {
        text-transform: $tt;
    }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

@mixin wrapp($top: 0, $bottom: 0, $right: 0, $left: 0) {
    top: $top;
    bottom: $bottom;
    right: $right;
    left: $left;
}

@mixin resp-ratio($x, $y, $pseudo: false) {
    $padding: unquote(math.div($y, $x) * 100 + "%");
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@function rem($value) {
    $remValue: math.div($value, $font-size) + rem;
    @return $remValue;
}

@function shadow-opacity($opacity) {
    @return 0px 2px 15px rgba(0, 0, 0, $opacity);
}

@mixin section-indent {
    padding-top: 100px;
    padding-bottom: 100px;

    @include media-breakpoint(md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @include media-breakpoint(sm) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@mixin container-out {
    margin-right: -1 * $container-indent;
    margin-left: -1 * $container-indent;
    padding-left: $container-indent;
    padding-right: $container-indent;

    @include media-breakpoint(sm) {
        margin-right: -1 * $container-indent-sm;
        margin-left: -1 * $container-indent-sm;
        padding-left: $container-indent-sm;
        padding-right: $container-indent-sm;
    }
}

@mixin card-glass {
    border-radius: $radius-default;
    background-color: $color-misc-card-glass-bg;
    border: 1px solid $color-misc-card-glass-border;
    box-shadow: $shadows-card-glass;
}

@mixin card-glass-hover {
    @include card-glass;
    transition: box-shadow 0.25s, border 0.25s;
    &:hover {
        box-shadow: $shadows-card-glass-hover;
        border-color: $color-misc-card-glass-border-hover;
        background-color: $color-misc-card-default-bg;
    }
}

@mixin card-white {
    border-radius: $radius-default;
    background-color: $color-misc-card-default-bg;

    border: 1px solid #fff;
    box-shadow: $shadows-general-default;
}

@mixin card-white-hover {
    @include card-white;
    transition: box-shadow 0.25s, border 0.25s;
    &:hover {
        box-shadow: $shadows-card-default-hover;
        border-color: $color-misc-card-default-border;
    }
}

@mixin card-cta {
    border-radius: $radius-default;
    background-image: $gradient-cta;
    box-shadow: $shadows-card-cta-default;
}

@mixin card-cta-hover {
    @include card-cta;
    transition: box-shadow 0.25s, border 0.25s;
    &:hover {
        box-shadow: $shadows-card-cta-hover;
        background-image: $gradient-cta-hover;
    }
}
