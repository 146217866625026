@import "variables", "media-queries", "mixins", "typography";
.level2 {
    @include h2;
}

.level3 {
    @include h3-small;

    &.variantBig {
        @include h3-big;
    }
}

.level4 {
    @include h4;
}

.level5 {
    @include h5;
}
