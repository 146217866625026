@import "variables", "media-queries", "mixins", "typography";
.footer {
    color: $color-text-primary;
    background-color: $color-basic-site-bg;
    position: relative;

    hr {
        @include hr;
    }
}

.inner {
    padding: 50px 0 25px;
    position: relative;
    display: grid;
    grid-template-columns: minmax(300px, 400px) 1fr 280px;
    grid-gap: 20px;
    justify-content: space-between;
    // border-bottom: 1px solid $color-white;

    @include media-breakpoint(md) {
        display: flex;
        grid-gap: 0 25px;
        flex-wrap: wrap;
    }

    @include media-breakpoint(sm) {
        padding: 30px 0 10px;
        display: block;
    }
}

.about {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint(md) {
        margin-bottom: 65px;
    }
}

.logo {
    margin-bottom: 30px;
}

.legal {
    display: flex;
    @include media-breakpoint(md) {
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        //align-items: center;
    }

    @include media-breakpoint(sm) {
        display: block;
    }
}

.legalInfo {
    @include media-breakpoint(sm) {
        margin-bottom: 10px;
    }
}

.legalLinks {
    position: absolute;
    right: 0;
    bottom: 35px;
    display: flex;
    gap: 20px;
    margin-left: 20px;
    max-width: calc(100% - 420px);

    @include media-breakpoint(lg) {
        flex-wrap: wrap;
        grid-gap: 10px;
    }

    @include media-breakpoint(md) {
        position: static;
        margin-top: 10px;
        margin-left: 0;
        flex-direction: column;
    }

    span {
        position: relative;
        color: $color-status-muted;
        &:not(:last-child) {
            &::after {
                position: absolute;
                display: block;
                content: "";
                width: 1px;
                height: 100%;
                background: currentColor;
                right: -10px;
                top: 0;

                @include media-breakpoint(lg) {
                    display: none;
                }
            }
        }
    }

    a {
        font-size: 12px;

        cursor: pointer;
        color: $color-status-muted;

        //@include media-breakpoint(sm) {
        //    display: inline-block;
        //    width: 100%;
        //}
    }
}

.menu,
.contacts {
    @include media-breakpoint(sm) {
        padding-top: 0;
    }
}

.contacts {
    font-size: 16px;

    a {
        transition: opacity 0.25s;
        &:hover {
            opacity: 0.6;
        }
    }

    .contactsText {
        @include body-medium;
        color: $color-basic-secondary;
        margin-bottom: 15px;
    }

    @include media-breakpoint(md) {
        order: 2;
        max-width: 310px;
    }

    @include media-breakpoint(sm) {
        padding-top: 0;
    }
}

.contactsTitle {
    @include h4;
    color: inherit;
    margin-bottom: 30px;

    @include media-breakpoint(md) {
        font-weight: 400;
        margin-bottom: 20px;
    }
}

.menu {
    @include media-breakpoint(md) {
        flex: 1;
    }
}

.copyright {
    display: block;
    color: $color-basic-secondary;

    @include media-breakpoint(md) {
        margin-top: 25px;
    }

    @include media-breakpoint(sm) {
        width: 100%;
    }
}

.socialsWrap {
    display: flex;
    margin: 30px 0;

    @include media-breakpoint(xs) {
        margin: 20px 0;
    }
}

.socialsList {
    display: flex;
}

.socialsItem {
    margin-left: 20px;
}
.socialsLink {
    transition: color 0.25s;
    display: block;
    width: 24px;
    height: 24px;

    span {
        font-size: 0;
    }

    &:hover {
        color: $color-basic-primary;
    }
}

.dmcaIcon {
    width: 80px;
    height: 37px;
    font-size: 0;

    @include media-breakpoint(md) {
        margin-left: 15px;
    }

    @include media-breakpoint(sm) {
        margin-left: -50px;
    }

    @include media-breakpoint(370px) {
        margin-left: -180px;
    }
}

.bottom {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint(md) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    @include media-breakpoint(sm) {
        align-items: flex-end;
    }
}

.contactsItem {
    margin-bottom: 20px;
    display: flex;
}

.contactIcon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    flex-shrink: 0;
}

.companyDescription {
    color: $color-text-secondary;

    p {
        margin-bottom: 7px;
    }
}
