@use "variables" as var;
@use "mixins" as mixin;
@use "media-queries" as media;

@mixin list-bullet {
    padding-left: 22px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: $color-basic-primary;
    }

    &:not(:last-child) {
        margin-bottom: 7px;
    }
}

@mixin list-bullet-white {
    @include list-bullet;

    &::before {
        background-color: $color-white;
    }
}

@mixin list-ordered {
    counter-reset: ol;
    > li {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        position: relative;
        padding-left: 36px;

        &::before {
            content: counter(ol);
            counter-increment: ol;
            position: absolute;
            padding-left: 1px;
            padding-top: 1px;
            top: 1px;
            left: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 14px;
            line-height: 1;
            color: $color-white;
            background-color: $color-basic-primary;
            border-radius: 50%;
        }
    }
}

@mixin promo-header-indent {
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 90px; //header height
    }
}

@mixin section-promo {
    color: $color-white;
    background-image: $gradient-main;
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 78px; //header height
    }
}

@mixin h1-big {
    @include mixin.fonts($desktop-heading-h1-big, 1.2, 700);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h1-big);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h1-big);
    }
}

@mixin h1-small {
    @include mixin.fonts($desktop-heading-h1-small, 1.4, 700);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h1-small);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h1-small);
    }
}

@mixin h2 {
    @include mixin.fonts($desktop-heading-h2, 1.4, 700);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h2);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h2);
    }
}

@mixin h3-big {
    @include mixin.fonts($desktop-heading-h3-big, 1.4, 600, -1);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h3-big);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h3-big);
    }
}

@mixin h3-small {
    @include mixin.fonts($desktop-heading-h3-small, 1.4, 600, -1px);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h3-small);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h3-small);
    }
}

@mixin h4 {
    @include mixin.fonts($desktop-heading-h4, 1.4, 600);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h4);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h4, $ls: -1);
    }
}

@mixin h5 {
    @include mixin.fonts($desktop-heading-h5, 1.4, 600);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h5);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h5);
    }
}

@mixin h6 {
    @include mixin.fonts($desktop-heading-h6, 1.4, 600);

    @include media-breakpoint(md) {
        @include mixin.fonts($tablet-heading-h6);
    }

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-heading-h6);
    }
}

@mixin body-big {
    @include mixin.fonts($desktop-body-big, 140%);

    @include media-breakpoint(md) {
        @include mixin.fonts($mobile-body-big, 156%);
    }
}

@mixin body-default {
    @include mixin.fonts($desktop-body-default, 150%);
    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-body-default);
    }
}

@mixin body-medium {
    @include mixin.fonts($desktop-body-medium, 140%);

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-body-medium);
    }
}

@mixin body-small {
    @include mixin.fonts($desktop-body-small, 140%);

    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-body-small);
    }
}

@mixin body-small-caps {
    @include mixin.fonts($desktop-body-small, $lh: 136%, $weight: 700, $ls: 2px);
    text-transform: uppercase;
    @include media-breakpoint(sm) {
        @include mixin.fonts($mobile-body-small);
    }
}

@mixin body-big-semibold {
    @include body-big;
    font-weight: 600;
}

@mixin body-default-semibold {
    @include body-default;
    font-weight: 600;
    line-height: 140%;
}

@mixin body-medium-semibold {
    @include body-medium;
    font-weight: 600;
}

@mixin body-small-semibold {
    @include body-small;
    font-weight: 600;
    line-height: 120%;
}

@mixin body-big-bold {
    @include body-big;
    font-weight: 700;
}

@mixin body-default-bold {
    @include body-default;
    font-weight: 700;
    line-height: 140%;
}

@mixin body-medium-bold {
    @include body-medium;
    font-weight: 700;
}

@mixin body-small-bold {
    @include body-small;
    font-weight: 700;
    line-height: 120%;
}

@mixin hr {
    background-color: $color-misc-hr;
    margin-top: 5px;
    margin-bottom: 5px;
}

@mixin base-article {
    @include body-default;

    :global {
        ul {
            margin-top: 30px;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &:first-child {
                margin-top: 0;
            }

            > li {
                position: relative;
                margin-bottom: 30px;
                padding-left: 30px;

                strong {
                    @include body-default-semibold;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: $color-basic-primary;
                }
            }
        }

        ol {
            margin-top: 30px;
            margin-bottom: 30px;

            @include list-ordered;
        }

        .title-sm {
            @include body-default-bold;
            margin-bottom: 10px;
        }

        .address-line {
            position: relative;
            padding-left: 32px;

            &::before {
                content: "";
                position: absolute;
                top: -1px;
                left: 3px;
                width: 14px;
                height: 19px;
                z-index: 2;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 17C8.5 17 12 9.24812 12 5.95775C12 2.66737 9.31371 0 6 0C2.68629 0 0 2.66737 0 5.95775C0 9.24812 3.5 17 6 17ZM6 8.61942C7.48059 8.61942 8.68085 7.42761 8.68085 5.95745C8.68085 4.48728 7.48059 3.29548 6 3.29548C4.5194 3.29548 3.31915 4.48728 3.31915 5.95745C3.31915 7.42761 4.5194 8.61942 6 8.61942Z' fill='%232D4EED'/%3E%3C/svg%3E");
            }

            &::after {
                content: "";
                position: absolute;
                top: -6px;
                left: -5px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                z-index: 1;
                box-shadow: $shadows-general-small;
            }
        }

        .img-link {
            display: inline-block;
            position: relative;
            padding-left: 60px;
            color: inherit;
            line-height: 40px;
            white-space: nowrap;
            font-weight: 700;
            transition: color 0.25s;
            padding-right: 18px;
            margin-bottom: 20px;

            &:hover {
                color: $color-basic-primary;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 10px;
                height: 10px;
                background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9C2.72308 7.27692 5.15385 4.84615 9 1M9 1V9M9 1H1.15385' stroke='%232D4EED' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }

            img {
                position: absolute;
                left: 0;
                top: 50%;
                width: 40px;
                height: 40px;
                transform: translateY(-50%);
            }
        }

        .cols-2 {
            columns: 2;

            @include media-breakpoint(sm) {
                columns: 1;
            }
        }

        a {
            color: $color-text-link;
        }

        h3 {
            font-size: 36px;

            @include media-breakpoint(sm) {
                font-size: 26px;
            }
        }
    }
}

@mixin table {
    background-color: #fff;
    overflow: auto;
    border: 1px solid $color-basic-border;
    border-radius: 8px;

    &:not(:first-child) {
        margin-top: 40px;
    }

    &:not(:last-child) {
        margin-bottom: 40px;
    }

    thead,
    tbody,
    tr,
    td,
    th {
        //text-align: left;
    }

    tr {
        padding: 20px;
    }

    th,
    td {
        padding: 20px;
        &:not(:last-child) {
            border-right: 1px solid $color-basic-border;
        }
    }

    tr {
        &:not(:last-child) {
            border-bottom: 1px solid $color-basic-border;
        }
    }

    th {
        @include body-default-bold;
    }
}
